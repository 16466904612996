



















import Vue from 'vue';

import DnbSelect from '@/components/DnbSelect.vue';

type Option = { value: string | undefined; label: string };

type Data = {
  loading: boolean;
  options: Option[];
};

export default Vue.extend({
  name: 'dnb-organization-select',
  components: { DnbSelect },

  props: {
    value: {
      type: [String, Array as () => string[]],
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    validationError: { type: Array, default: undefined },
    type: {
      type: String,
      default: 'text',
    },
    help: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      options: ICONS.map((icon: string) => ({
        label: icon,
        value: icon,
        icon,
      })),
      loading: false,
    };
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
