




























































































































































































































































































































































































































































































































































































import { ApiInputDto } from '@portals/shared/admin/ApiDto';
import Vue from 'vue';

import AdminButtonGroup from '@/components/AdminButtonGroup.vue';
import AdminPopUp from '@/components/AdminPopUp.vue';
import AdminSection from '@/components/AdminSection.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbCheckbox from '@/components/DnbCheckbox.vue';
import DnbIconSelect from '@/components/DnbIconSelect.vue';
import DnbInput from '@/components/DnbInput.vue';
import DnbOrganizationSelect from '@/components/DnbOrganizationSelect.vue';
import DnbSelect from '@/components/DnbSelect.vue';
import DnbTeamSelect from '@/components/DnbTeamSelect.vue';
import DnbTextarea from '@/components/DnbTextarea.vue';
import DnbToggle from '@/components/DnbToggle.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import Loader from '@/components/Loader.vue';
import { deleteApi, fetchApi, saveApi } from '@/service/apiService';
import { fetchTags } from '@/service/tagService';

import { Breadcrumb } from '../../models/Breadcrumb';
import { scrollToError } from '../../utils';

type Option = {
  value: string | undefined;
  label: string;
};

type Data = {
  loading: boolean;
  api: ApiInputDto;
  create: boolean;
  apiStageOptions: { value: string; label: string }[];
  updating: boolean;
  errors: Record<string, unknown>;
  environmentErrors: Record<string, unknown>;
  errorMessage: string | undefined;
  uniqueEnvironmentsError: string | undefined;
  showDeletePopup: boolean;
  selectedHelpText: string;
  displayHelpText: boolean;
  tags: string[];
  tagFilter: Record<string, boolean>;
};

const defaultApi: ApiInputDto = {
  ordering: 999,
  attachable: false,
  availableInLiveMode: false,
  enableStatus: false,
  stage: 'unpublished',
  organizations: [],
  teams: [],
  environments: [
    {
      slug: 'production',
      name: 'Production',
      isDefault: true,
      isInternal: false,
    },
  ],
  name: '',
  icon: '',
  slug: '',
  isPublic: false,
  liveModeWebhookSecret: '',
  testModeWebhookSecret: '',
  liveModeWebhookUrl: '',
  testModeWebhookUrl: '',
  classification: 'domain',
  tags: [],
  appId: null,
};

export default Vue.extend({
  name: 'edit-api',
  components: {
    DnbInput,
    DnbTextarea,
    DnbToggle,
    DnbWarning,
    DnbSelect,
    DnbOrganizationSelect,
    DnbIconSelect,
    DnbTeamSelect,
    AdminSection,
    Loader,
    DnbButton,
    AdminPopUp,
    AdminButtonGroup,
    Breadcrumbs,
    DnbCheckbox,
  },
  data(): Data {
    return {
      loading: false,
      api: { ...defaultApi },
      create: !this.$route.params.apiId,
      apiStageOptions: [
        { value: 'unpublished', label: 'Unpublished' },
        { value: 'lab', label: 'Lab' },
        { value: 'upcoming', label: 'Upcoming' },
        { value: 'deprecated', label: 'Deprecated' },
        { value: 'launched', label: 'Launched' },
      ],
      updating: false,
      errorMessage: undefined,
      errors: {},
      environmentErrors: {},
      uniqueEnvironmentsError: 'Environments must be unique',
      showDeletePopup: false,
      selectedHelpText: '',
      displayHelpText: false,
      tags: [],
      tagFilter: {},
    };
  },
  computed: {
    selectedTags(): string[] {
      return this.tags.filter((tag) => this.tagFilter[tag]);
    },

    onlyUniqueEnvironments(): boolean {
      const keys = this.api.environments.map(({ slug }) => slug);
      const uniqueKeys = new Set(keys);
      return keys.length === uniqueKeys.size;
    },
    hasDefaultEnvironment(): boolean {
      return this.api.environments.some(({ isDefault }) => isDefault);
    },
    tagsString: {
      get(): string {
        return this.api.tags.join(',');
      },
      set(tags: string) {
        this.api.tags = tags.split(',');
      },
    },
    classificationOptions(): Option[] {
      return [
        { value: 'system', label: 'System' },
        { value: 'domain', label: 'domain' },
        { value: 'experience', label: 'Experience' },
        { value: 'partner', label: 'Partner' },
        { value: 'public', label: 'Public' },
      ];
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          name: 'APIs',
          path: '/apis',
        },
        {
          name: this.api.name || 'Create API',
        },
      ];
    },
  },
  async mounted() {
    this.loading = true;
    const apiId = this.$route.params.apiId;
    if (apiId) {
      const api = await fetchApi(apiId);
      this.api = { ...api, ordering: api.ordering };
      this.tags = (await fetchTags()).map((tag) => tag.title);
      this.tagFilter = api.tags.reduce(
        (accumulator: Record<string, boolean>, tag) => {
          accumulator[tag] = true;
          return accumulator;
        },
        {},
      );
    } else {
      this.api = { ...defaultApi };
    }

    this.loading = false;
  },
  methods: {
    resetScropes() {
      if (!this.api.attachable) {
        this.api.liveModeScope = '';
        this.api.testModeScope = '';
      }
    },
    addNewEnvironment() {
      if (this.api.id) {
        this.api.environments.push({
          apiId: this.api.id,
          slug: '',
          name: '',
          isDefault: false,
          isInternal: true,
        });
      } else {
        this.api.environments.push({
          slug: '',
          name: '',
          isDefault: false,
          isInternal: true,
        });
      }
    },
    removeEnvironment(index: number) {
      if (this.api.environments.length > 1) {
        this.api.environments.splice(index, 1);
      }
    },
    onDefaultEnvironmentChange(index: number) {
      this.api.environments.forEach((environment, i) => {
        if (i !== index) {
          environment.isDefault = false;
        }
      });
    },
    deleteApiPopUp() {
      this.showDeletePopup = !this.showDeletePopup;
    },
    async save() {
      this.errorMessage = undefined;
      this.updating = true;
      try {
        if (!this.onlyUniqueEnvironments) {
          this.updating = false;
          return;
        }
        this.resetScropes();
        this.api.tags = this.selectedTags;
        await saveApi(this.api);
        this.$router.push('/apis');

        this.updating = false;
      } catch (error) {
        this.updating = false;
        if (
          error &&
          error.response &&
          error.response.data.code === 'ValidationError'
        ) {
          this.errors = error.response.data.errors;

          // array of errors
          if (this.errors.environments) {
            //@ts-ignore
            this.environmentErrors = this.errors.environments.reduce(
              //@ts-ignore
              (object, item) => {
                const key = Object.keys(item)[0];
                object[key] = item[key];
                return object;
              },
            );
          }
          Vue.nextTick(scrollToError);
        } else if (error && error.response) {
          this.errorMessage = error.response.data.message;
        }
      }
    },
    async deleteItem() {
      try {
        await deleteApi(this.api.id!);
        this.$router.push('/apis');
      } catch (error) {
        if (error && error.response) {
          this.errorMessage = error.response.data.message;
        }
      } finally {
        this.showDeletePopup = false;
      }
    },
  },
});
